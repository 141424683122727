<nxh-alert type="warning" alertTitle="When using the new nhForm you should use nh-submit-button in stead!"></nxh-alert>

<p>
  The <code>SubmitButtonComponent</code> has a couple of inputs to enable/disable the button, show/hide a spinner and
  change the label. Note that when switching on the spinner, the button will be disabled as well.
</p>
<p>
  Also, when using the new flavour of forms, you don't have to use the inputs at all. Submitting the form will
  automatically switch on the spinner and disable the button.
</p>

<h5>Submit button default</h5>
<deprecated-submit-button (click)="clickAction()" [showSpinner]="isLoading"></deprecated-submit-button>
<br />
<pre><code [highlight]="defaultCode" language="html"></code></pre>
<br />
<hr />
<br />
<h5>Submit button colorTypes</h5>
<div class="btn-types">
  <deprecated-submit-button btnType="primary" (click)="clickAction()" [showSpinner]="isLoading">
  </deprecated-submit-button>
  <deprecated-submit-button btnType="warning" (click)="clickAction()" [showSpinner]="isLoading">
  </deprecated-submit-button>
  <deprecated-submit-button btnType="danger" (click)="clickAction()" [showSpinner]="isLoading">
  </deprecated-submit-button>
  <deprecated-submit-button btnType="success" (click)="clickAction()" [showSpinner]="isLoading">
  </deprecated-submit-button>
</div>
<br />
<pre><code [highlight]="colorTypeCode" language="html"></code></pre>
<br />
<hr />
<br />
<h5>Submit button with custom label</h5>
<deprecated-submit-button label="Custom label here" (click)="clickAction2()" [showSpinner]="isLoading2">
</deprecated-submit-button>
<br />
<pre><code [highlight]="customLabelCode" language="html"></code></pre>
<br />
<hr />
<br />
<h5>Submit button disabled</h5>
<deprecated-submit-button [disabled]="true" label="I'm disabled"></deprecated-submit-button>
<br />
<pre><code [highlight]="disabledCode" language="html"></code></pre>

<h5>No icon</h5>
<deprecated-submit-button (click)="clickAction()" [showSpinner]="isLoading" [showIcon]="false">
</deprecated-submit-button>
<pre><code [highlight]="noIconCode" language="html"></code></pre>
