import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccessRole,
  OrganisationAccessRole,
  ResourceName,
  Role,
  RoleLeaf,
  User,
  UserContext,
  UserContextInfo,
  UserInfo,
  UserSettingsBody,
} from '@nexuzhealth/shared-domain';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { FAR_AWAY_AS_STRING, getHttpParams } from '@nexuzhealth/shared-util';
import { UserContextStore } from '../state/user-store.service';

export const allAccessRoles: Role[] = [
  {
    name: AccessRole.generalPractitioner,
    sendVersion: '2021-09-06T12:45:14.592096206Z',
    description: 'General practitioner',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-general_practitioner',
  },
  {
    name: AccessRole.homeNurse,
    sendVersion: '2021-09-06T12:45:14.592187915Z',
    description: 'Home nurse',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-home_nurse',
  },
  {
    name: AccessRole.medicalAssistant,
    sendVersion: '2021-09-06T12:45:14.592297022Z',
    description: 'Medical assistant',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-medical_assistant',
  },
  {
    name: AccessRole.serviceDesk,
    sendVersion: '2021-09-06T12:45:14.592297022Z',
    description: 'Service desk',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-service_desk',
  },
  {
    name: AccessRole.practicalNurse,
    sendVersion: '2022-04-11T14:15:36.330684286Z',
    description: 'Practical nurse',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-practical_nurse',
  },
  {
    name: AccessRole.officeCoordinator,
    sendVersion: '2022-04-11T14:15:36.330684286Z',
    description: 'Practical nurse',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-office_coordinator',
  },
  {
    name: AccessRole.nurseAssistant,
    sendVersion: '2022-04-11T14:15:36.330684286Z',
    description: 'Practical nurse',
    startTime: '1900-01-01T00:00:00Z',
    endTime: FAR_AWAY_AS_STRING,
    assignable: true,
    translationKey: '_role.system-common-nurse_assistant',
  },
];

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private http: HttpClient,
    private userContextStore: UserContextStore,
  ) {}

  getUserAndContexts(): Observable<UserInfo> {
    const url = `api/iam/login/v1/userinfo`;
    return this.http.get<UserInfo>(url);
  }

  getUser(userName: ResourceName, view: 'DEFAULT' | 'BASIC' | 'FULL'): Observable<User> {
    const url = `api/iam/identity/v1/${userName}`;
    return this.http
      .get<{ user: User; view: 'DEFAULT' | 'BASIC' | 'FULL' }>(url, { params: getHttpParams({ view: view }) })
      .pipe(pluck('user'));
  }

  listUsers(practitionerName: string, view: 'DEFAULT' | 'BASIC' | 'FULL'): Observable<User[]> {
    const url = `api/admin/iam/identity/v1/users`;
    return this.http
      .get<{ data: User[] }>(url, {
        params: getHttpParams({ view: view, practitionerName: practitionerName }),
      })
      .pipe(pluck('data'));
  }

  getUserContext(userContextName: ResourceName): Observable<UserContext> {
    const url = `api/iam/identity/v2/${userContextName}`;
    // Note: since the backend currently reports incorrect assignable values (it is always false), we map
    // assignability in the frontend.
    return combineLatest([
      this.http.get<UserContext>(url),
      this.getAllAccessRoles().pipe(map((assignableAccessRoles) => assignableAccessRoles.map((role) => role.name))),
    ]).pipe(
      map(([userContext, assignableAccessRoleNames]) => {
        return {
          ...userContext,
          rolesActive:
            userContext.rolesActive === undefined
              ? undefined
              : userContext.rolesActive.map((role) => ({
                  ...role,
                  assignable: assignableAccessRoleNames.includes(role.name),
                })),
        };
      }),
      tap((userContext) => {
        this.userContextStore.upsert(userContext.name, userContext);
      }),
    );
  }

  searchUserContexts(
    view: 'BASIC' | 'FULL',
    filter: { entityName?: string; accessRoles?: AccessRole[] },
  ): Observable<UserContextInfo[]> {
    return this.http
      .get<{ data: UserContextInfo[] }>(`api/iam/identity/v1/contexts:search`, {
        params: getHttpParams({
          view: view,
          entityName: filter.entityName,
          roleNames: filter.accessRoles,
        }),
      })
      .pipe(pluck('data'));
  }

  getAccessRoles(userContextName: ResourceName): Observable<AccessRole[]> {
    if (!userContextName) {
      return of([]);
    }
    const url = `api/iam/identity/v1/${userContextName}/derivedroles`;
    return this.http.get<Record<'data', AccessRole[]>>(url).pipe(
      pluck('data'),
      catchError((err) => {
        console.error('Error while fetching accessRoles', err);
        return of([]);
      }),
    );
  }

  getAccessRoleTree(organisationName: string | null = null): Observable<RoleLeaf[]> {
    let url = 'api/iam/accessrole/v1/tenants/system/accessroles:tree';
    if (organisationName) {
      url = url + `?entity_name=${organisationName}`;
    }
    return this.http.get<{ data: RoleLeaf[] }>(url).pipe(pluck('data'));
  }

  // Made this function private, since we want to get rid of the static list here.
  private getAllAccessRoles(): Observable<Role[]> {
    // let httpParams = new HttpParams();
    // httpParams = httpParams.set('assignableOnly', assignableOnly);
    // httpParams = httpParams.append('includeAdmin', includeAdminRole);
    //
    // return this.http
    //   .get<Role[]>('api/iam/accessrole/v1/tenants/system/accessroles', { params: httpParams })
    //   .pipe(pluck('data')); // not sure about this line.

    return of(allAccessRoles);
  }

  setUserAutoImportEHealthBox(userName: string, enableAutoImport: boolean) {
    const url = `api/iam/identity/v1/${userName}/settings`;
    const body: UserSettingsBody = {
      settingId: 'ehealth-box-automatic-import-enabled',
      value: enableAutoImport.toString(),
    };

    return this.http.post(url, body);
  }

  listOrganisationRoles(): Observable<OrganisationAccessRole[]> {
    const url = 'api/iam/identity/v1/organisation:roles';
    return this.http.get<{ organisationRoles: OrganisationAccessRole[] }>(url).pipe(pluck('organisationRoles'));
  }
}
